.i-like-this ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3em;
}
.i-like-this ul li {
  font-size: 1.3em;
  width: 50%;
  margin-bottom: 1.2em;
  transition: all 6s cubic-bezier(0.24, 1, 0.23, 0.99);
  text-align: center;
}
@media all and (min-width: 980px) {
  .i-like-this ul li:hover {
    transition: all 200ms cubic-bezier(0.24, 1, 0.23, 0.99);
    letter-spacing: 0.12em;
    transform: scale(1.7);
    font-weight: bold;
    user-select: none;
  }
}
@media all and (max-width: 980px) {
  .i-like-this ul li {
    transition: all 2s cubic-bezier(0.24, 1, 0.23, 0.99);
    text-align: center;
  }
  .sp-active {
    transition: all 0.2s;
    letter-spacing: 0.08em;
    transform: scale(1.3);
    font-weight: bold;
    user-select: none;
  }
}
/* .i-like-this ul li:nth-child(2n) {
  text-align: left;
  width: 50%;
}
.i-like-this ul li:nth-child(3n) {
  text-align: center;
  width: 45%;
}
.i-like-this ul li:nth-child(5n) {
  text-align: center;
  width: 100%;
}
.i-like-this ul li:nth-child(11n) {
  text-align: left;
  width: 65%;
}
.i-like-this ul li:nth-child(13n) {
  text-align: center;
  width: 45%;
}
.i-like-this ul li:nth-child(17n) {
  text-align: right;
  width: 50%;
}

.i-like-this ul li:nth-child(2n+1) {
  text-align: left;
    width: 40%;
}
.i-like-this ul li:nth-child(3n+2) {
  text-align: right;
    width: 25%;
}
.i-like-this ul li:nth-child(5n+3) {
  text-align: center;
    width: 100%;
}
.i-like-this ul li:nth-child(11n+5) {
  text-align: left;
    width: 50%;
}
.i-like-this ul li:nth-child(13n+11) {
  text-align: center;
  width: 25%;
}
.i-like-this ul li:nth-child(17n+13) {
  text-align: right;
  width: 25%;
} */
